// Libraries
import * as React from 'react'
import { useStaticQuery, graphql } from "gatsby"

// Components
import { GatsbyImage } from 'gatsby-plugin-image'

const VenuePartners = () => {
	const data = useStaticQuery(graphql`
		query VenuePartnersQuery {
			venues: allContentManagementContentVenues {
				nodes {
					id
					name
					image_logo_gatsby {
						childImageSharp {
							gatsbyImageData(width: 525)
						}
					}
				}
			}
		}
    `)

	const venues = data.venues.nodes

	return (
		<div className="venue-partners" style={{ '--item-count': venues.length }}>
			{ venues.map((venue, index) => {
				return venue.image_logo_gatsby && <div className="venue-partners__venue" key={index}>
					<GatsbyImage objectFit={ "contain" } alt={ venue.name } image={ venue.image_logo_gatsby.childImageSharp.gatsbyImageData } />
				</div>
			})}
		</div>
	)
}

export default VenuePartners
