// Libraries
import React from 'react'
import {useStaticQuery, graphql, Link} from "gatsby"

// Hooks
import useIsMobile from '../hooks/useIsMobile'

// Components
import Slider from "react-slick"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'

const TestimonialQuotes = () => {
	const isMobile = useIsMobile()
	const data = useStaticQuery(graphql`
		query TestimonialQuotesQuery {
			contentManagement(slug: {eq: "testimonial-quotes"}) {
				id
				content {
					quotes {
						name
						link
						event
						quote
					}
				}
			}
		}
    `)

	const sliderSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : 3,
		slidesToScroll: 1,
		centerMode: true,
	}

	const quotes = data.contentManagement.content.quotes

	return (
		<div className="testimonial-quotes">
			<Slider { ...sliderSettings }>
				{ quotes.map((quote, index) =>
					<blockquote className="testimonial-quotes__quote" key={ index }>
						<div className="testimonial-quotes__quote__inner">
							<svg className="testimonial-quotes__quote-mark" xmlns="http://www.w3.org/2000/svg" width="106.365" height="92.138" viewBox="0 0 106.365 92.138">
								<path id="Path_496" data-name="Path 496" d="M50.729,114.456H1.611V76.63q0-20.889,8.694-32.971T40.115,22.318l10.614,20.1q-12.985,6.1-17.953,12.138a24.542,24.542,0,0,0-5.533,14.284H50.729Zm57.248,0H58.859V76.63q0-20.889,8.694-32.971T97.363,22.318l10.614,20.1q-12.985,6.1-17.953,12.138A24.542,24.542,0,0,0,84.49,68.839h23.486Z" transform="translate(107.977 114.456) rotate(180)" fill="#4ce0fa" opacity="0.289"/>
							</svg>
							<footer className="testimonial-quotes__quote__author">
								<p className="testimonial-quotes__quote__title">{ quote.name }</p>
								<p className="testimonial-quotes__quote__event">{ quote.event }</p>
							</footer>

							<p className="testimonial-quotes__quote__content">{ quote.quote }</p>
							{quote.link && <Link to={ quote.link } className="testimonial-quotes__quote__link" aria-label={`Read more about ${ quote.name }'s testimonial`}>
								Read more
								<span>
									<FontAwesomeIcon icon={ faChevronRight } />
								</span>
							</Link>}
						</div>
					</blockquote>) }
			</Slider>
		</div>
	)
}

export default TestimonialQuotes
