
// Libraries
import React, {useState, useEffect, useRef} from 'react'
import {graphql} from 'gatsby'

// Services
import AuthService from '../services/auth'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import AnchorButton from '../components/anchorButton'
import Button from '../components/button'
import HowItWorks from '../components/howItWorks'
import FeaturedEventsHome from '../components/featuredEventsHome'
import EventTiles from '../components/eventTiles'
import HomeSlider from '../components/homeSlider'
import VenuePartners from '../components/venuePartners'
import TestimonialQuotes from '../components/testimonialQuotes'

const authService = new AuthService()

export default function IndexPage({data}) {
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	// Get featured events from GraphQL query
	const featuredEvents = data?.featuredEvents.nodes

	// Get pageContent
	const pageContent = data?.contentManagement.content

	useEffect(() => {
		authService.isLoggedIn().then(response => {
			setIsLoggedIn(response)
		})
	})

	// scroll to sections
	const howItWorksComponent = useRef(null)
	const featuredEventsComponent = useRef(null)
	const scrollToHowItWorks = () => howItWorksComponent.current.scrollIntoView()
	const scrollToFeaturedEvents = () => featuredEventsComponent.current.scrollIntoView()

	return (
		<Layout>
			<Seo title="Home"/>
			<Hero fullHeight bg="home" video bgText>
				<h1>{pageContent?.hero_title}</h1>
				{isLoggedIn
					? <>
						<p>Explore upcoming events and apply for tickets</p>
						<div>
							<Button to={pageContent?.hero_button3_url}>{pageContent?.hero_button3_text}</Button>
						</div>
					</>
					: <>
						<p>{pageContent?.hero_subtitle}</p>
						<div>
							<Button to={pageContent?.hero_button1_url}>{pageContent?.hero_button1_text}</Button>
							<Button to={pageContent?.hero_button2_url} hollow>{pageContent?.hero_button2_text}</Button>
						</div>
					</>}
				<AnchorButton onClick={scrollToHowItWorks} colorMalibu>How It Works</AnchorButton>
			</Hero>
			<section>
				<TestimonialQuotes />
			</section>
			<section>
				<VenuePartners />
			</section>
			<section ref={howItWorksComponent}>
				<HowItWorks pageContent={pageContent} anchorLink={<AnchorButton onClick={scrollToFeaturedEvents}>See Events</AnchorButton>} />
			</section>
			<section ref={featuredEventsComponent}>
				<FeaturedEventsHome pageContent={pageContent} />
				<EventTiles events={featuredEvents} />
				<HomeSlider events={featuredEvents} />
			</section>
		</Layout>
	)
}

export const query = graphql`
{
	featuredEvents: allFeaturedEvent(filter: {type: {eq: "featured"}}, limit: 3) {
		nodes {
			id
			event {
				id
				slug
				name
				starts
				image
				venue {
					name
				}
				type {
					name
				}
				artist {
					name
				}
			}
			image_gatsby {
				childImageSharp {
					gatsbyImageData(width: 1400)
				}
			}
		}
	}
  
	contentManagement(slug: {eq: "home"}) {
		id
		content {
			hero_title
			hero_subtitle
			hero_button1_text
			hero_button1_url
			hero_button2_text
			hero_button2_url
			hero_button3_text
			hero_button3_url
			how_it_works_title
			how_it_works_step1
			how_it_works_step2
			how_it_works_step3
			how_it_works_outro_large
			how_it_works_outro_small
			featured_events_title
			featured_events_intro
			featured_events_button_text
			featured_events_button_url
		}
	}
}
`
