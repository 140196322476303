
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons'

class AnchorButton extends React.Component {
	render() {
		let classes = Classnames([
			'anchor-button',
			{
				'anchor-button--color-malibu': this.props.colorMalibu,
			},
		])

        const {onClick, children} = this.props

		const buttonText = <span className="anchor-button__text">{children}</span>
    
		return (
			<button onClick={onClick} className={classes}>
				{buttonText}
				<FontAwesomeIcon icon={faArrowDown} />
			</button>
		)
	}
}

export default AnchorButton
