// Libraries
import * as React from 'react'
import {useState, createRef} from "react";

// Animations
import { Player } from '@lottiefiles/react-lottie-player'
import { create } from '@lottiefiles/lottie-interactivity'
import calendarJson from '../animations/calendar.json'
import ticketJson from '../animations/ticket.json'
import trophyJson from '../animations/trophy.json'

const HowItWorks = ({pageContent, anchorLink}) => {
	const [calendarLottie, setCalendarLottie] = useState(null)
	let calendarAnimationPlayer = createRef();

	const [ticketLottie, setTicketLottie] = useState(null)
	let ticketAnimationPlayer = createRef();

	const [trophyLottie, setTrophyLottie] = useState(null)
	let trophyAnimationPlayer = createRef();

	return (
		<div className="how-it-works">
			<div className="works-left">
				<div className="content-container">
					<h2>{pageContent?.how_it_works_title}</h2>
					<div className='list'>
						<div className='item'>
							<div className='animation-container animation-container--calendar'>
								<Player
									src={calendarJson}
									ref={calendarAnimationPlayer}
									lottieRef={(instance) => {
										setCalendarLottie(instance)
									}}
									onEvent={(event) => {
										if (event === 'load') {
											create({
												player: calendarLottie,
												mode:"scroll",
												actions: [
													{
														visibility: [0.15, 1.0],
														type: "play",
														frames: [[0, 113], [114, 115]]
													},
												]
											});
										}

									}}
									autoplay={false}
									loop={false} />
							</div>
							<h3 className='browser'><span>{pageContent?.how_it_works_step1}</span></h3>
						</div>
						<div className='item'>
							<div className="animation-container animation-container--ticket">
								<Player
									src={ticketJson}
									ref={ticketAnimationPlayer}
									lottieRef={(instance) => {
										setTicketLottie(instance)
									}}
									onEvent={(event) => {
										if (event === "load") {
											create({
												mode: "scroll",
												player: ticketLottie,
												actions: [
													{
														visibility: [0.15, 1],
														type: "play",
														frames: [[0, 88], [89, 90]]
													}
												]
											});
										}
									}}
									autoplay={false}
									loop={false} />
							</div>
							<h3 className='ballot'><span>{pageContent?.how_it_works_step2}</span></h3>
						</div>
						<div className='item'>
							<div className="animation-container animation-container--trophy">
								<Player
									src={trophyJson}
									ref={trophyAnimationPlayer}
									lottieRef={(instance) => {
										setTrophyLottie(instance)
									}}
									onEvent={(event) => {
										if (event === "load") {
											create({
												mode: "scroll",
												player: trophyLottie,
												actions: [
													{
														visibility: [0.15, 1],
														type: "play",
														frames: [[0, 68], [69, 70]]
													}
												]
											});
										}
									}}
									autoplay={false}
									loop={false} />
							</div>
							<h3 className='tickets'><span>{pageContent?.how_it_works_step3}</span></h3>
						</div>
					</div>
					<p>{pageContent?.how_it_works_outro_small}</p>
				</div>
			</div>

			<div className="works-right">
				<div className="text-container" dangerouslySetInnerHTML={{ __html: pageContent?.how_it_works_outro_large }} />
				{anchorLink}
			</div>
		</div>
	)
}

export default HowItWorks
