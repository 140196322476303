 
// Libraries
import * as React from 'react'

// Components
import Button from '../components/button'

const FeaturedEventsHome = ({pageContent}) => (
	<div className='featured-events-home'>
		<div className='text-container'>
			<h2>{pageContent?.featured_events_title}</h2>
			<p>{pageContent?.featured_events_intro}</p>
			<Button to={pageContent?.featured_events_button_url}>{pageContent?.featured_events_button_text}</Button>
		</div>
	</div>
)

export default FeaturedEventsHome
